import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import {
  GET_TESTIMONIALS,
  GET_POPULAR_TESTIMONIALS,
  GET_TESTIMONIALS_BY_IDS,
  GET_TESTIMONIALS_BY_CATEGORY,
  GET_TESTIMONIAL_CATEGORIES,
  GET_SINGLE_TESTIMONIAL_BY_SLUG,
} from "~/graphql/GetTestimonials";

export const useTestimonials = defineStore("testimonials", () => {
  const PAGE_SIZE = 6;
  const singleTestimonialSlug = ref("");
  let currentPage = ref(1);

  const { result: singleTestimonialResult, loading: singleTestimonialLoading } =
    useQuery(GET_SINGLE_TESTIMONIAL_BY_SLUG, {
      id: singleTestimonialSlug,
    });
  const singleTestimonial = computed(() => singleTestimonialResult.value ?? []);

  const { result: testimonialCategoriesResult } = useQuery(
    GET_TESTIMONIAL_CATEGORIES
  );
  const testimonialCategories = computed(
    () => testimonialCategoriesResult.value?.testimonialCategories.nodes ?? []
  );

  const {
    result: popularTestimonialsResult,
    loading: popularTestimonialsLoading,
  } = useQuery(GET_POPULAR_TESTIMONIALS);

  const popularTestimonials = ref([]);
  const categorySlug = ref("");

  // ToDo: This could use a refactor
  // First we query to get the database IDs of the selected popular testimonials on the options page. Unfortunately when you return the posts as objects, it does not include all the information needed. (Could probably change this on the theme side by fetching and including the data where we add the hook in ./hooks/graphql.php)
  // Instead, we need to use those IDs fetch their information in a follow-up query.
  // These need to happen asynchronously as one query depends on the other, hence setting up the watcher.
  // The problem is this is slow. Probably need to revisit the php hook.
  watch(popularTestimonialsLoading, (newPopularTestimonialsLoading) => {
    if (!newPopularTestimonialsLoading) {
      const popularTestimonialIds = computed(() => {
        let parsedJSON = {};
        try {
          parsedJSON = JSON.parse(
            popularTestimonialsResult?.value?.popularTestimonials
          );
        } catch (e) {
          // console.log("Invalid popularTestimonials JSON:", e);
          return {};
        }
        return parsedJSON;
      });

      const {
        result: getTestimonialsByIdsResult,
        loading: getTestimonialsByIdsLoading,
      } = useQuery(GET_TESTIMONIALS_BY_IDS, {
        in: popularTestimonialIds.value.testimonials,
      });

      popularTestimonials.value = computed(
        () =>
          getTestimonialsByIdsResult.value?.testimonials.edges.map(
            (edge) => edge.node
          ) ?? []
      );
    }
  });

  const { result, loading, fetchMore } = useQuery(GET_TESTIMONIALS, {
    first: PAGE_SIZE,
    last: null,
    after: null,
    before: null,
  });
  const testimonials = computed(
    () => result.value?.testimonials.edges.map((edge) => edge.node) ?? []
  );

  // Avoiding getting post total as it's an expensive operation and I have deactivatied the plugin
  // const testimonialCount = computed(() => result.value?.testimonials.pageInfo.total ?? 0);

  const hasNextPage = computed(
    () => result.value?.testimonials.pageInfo.hasNextPage ?? false
  );
  const hasPreviousPage = computed(
    () => result.value?.testimonials.pageInfo.hasPreviousPage ?? false
  );
  const startCursor = computed(
    () => result.value?.testimonials.pageInfo.startCursor ?? ""
  );
  const endCursor = computed(
    () => result.value?.testimonials.pageInfo.endCursor ?? ""
  );

  const loadMore = (direction = "next") => {
    let first, last, after, before;

    switch (direction) {
      case "previous":
        if (!hasPreviousPage) return;
        last = PAGE_SIZE;
        before = result.value?.testimonials.pageInfo.startCursor;
        currentPage.value = currentPage.value <= 1 ? 1 : currentPage.value - 1;
        break;
      case "next":
      default:
        if (!hasNextPage) return;
        first = PAGE_SIZE;
        after = result.value?.testimonials.pageInfo.endCursor;
        currentPage.value = currentPage.value + 1;
        break;
    }

    fetchMore({
      variables: {
        first: first,
        last: last,
        after: after,
        before: before,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult ?? previousResult;
      },
    });
  };

  let testimonialsByCategoryResult,
    testimonialsByCategoryLoading,
    testimonialsByCategoryFetchMore;
  const testimonialsByCategory = ref([]);
  const testimonialsByCategoryPageInfo = ref({});
  watch(categorySlug, (newCategorySlug) => {
    const {
      result: testimonialsByCategoryResult,
      loading: testimonialsByCategoryLoading,
      fetchMore: testimonialsByCategoryFetchMore,
    } = useQuery(GET_TESTIMONIALS_BY_CATEGORY, {
      categorySlug: newCategorySlug,
      first: PAGE_SIZE,
      last: null,
      after: null,
      before: null,
    });

    testimonialsByCategory.value = computed(() => {
      return (
        testimonialsByCategoryResult?.value?.testimonialCategories?.edges?.[0]?.node?.testimonials?.edges?.map(
          (edge) => edge.node
        ) ?? []
      );
    });

    testimonialsByCategoryPageInfo.value = computed(() => {
      return (
        testimonialsByCategoryResult?.value?.testimonialCategories?.edges?.[0]
          ?.node?.testimonials?.pageInfo ?? {}
      );
    });
  });

  const hasNextPageCategoryPages = computed(
    () => testimonialsByCategoryPageInfo.hasNextPage ?? false
  );
  const hasPreviousPageCategoryPages = computed(
    () => testimonialsByCategoryPageInfo.hasPreviousPage ?? false
  );
  const startCursorCategoryPages = computed(
    () => testimonialsByCategoryPageInfo.startCursor ?? ""
  );
  const endCursorCategoryPages = computed(
    () => testimonialsByCategoryPageInfo.endCursor ?? ""
  );

  const loadMoreCategoryPages = (direction = "next") => {
    let first, last, after, before;

    switch (direction) {
      case "previous":
        if (!hasPreviousPageCategoryPages) return;
        last = PAGE_SIZE;
        before = testimonialsByCategoryPageInfo.startCursor;
        currentPage.value = currentPage.value <= 1 ? 1 : currentPage.value - 1;
        break;
      case "next":
      default:
        if (!hasNextPageCategoryPages) return;
        first = PAGE_SIZE;
        after = testimonialsByCategoryPageInfo.endCursor;
        currentPage.value = currentPage.value + 1;
        break;
    }

    testimonialsByCategoryFetchMore({
      variables: {
        categorySlug: categorySlug,
        first: first,
        last: last,
        after: after,
        before: before,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult ?? previousResult;
      },
    });
  };

  return {
    loading,
    testimonialCategories,
    categorySlug,
    currentPage,
    hasNextPage,
    hasNextPageCategoryPages,
    hasPreviousPage,
    hasPreviousPageCategoryPages,
    popularTestimonials,
    testimonials,
    testimonialsByCategory,
    testimonialsByCategoryLoading,
    loadMore,
    loadMoreCategoryPages,
    singleTestimonial,
    singleTestimonialLoading,
    singleTestimonialSlug,
  };
});
